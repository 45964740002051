import { useEventCount } from 'modules/socket/hooks'
import { OptionItem } from '../types'
import { useFilteredOptionsByPerms } from '../utils'
import { OPTIONS } from './CommonOptions'
import useMenuOptions from './useMenuOptions'

export const MOBILE_OPTIONS: OptionItem[] = [
  {
    translateKey: 'main',
    key: 'main',
    access: { extra: true },
    children: [
      OPTIONS.MY_TASKS,
      OPTIONS.BIDS,
      OPTIONS.DEALS,
      OPTIONS.DISPATCHING,
      OPTIONS.SHIPMENTS_CONTROL,
      OPTIONS.CALCULATOR,
      OPTIONS.BID_CONTRACTS,
    ],
  },
  {
    translateKey: 'usersAndCompanies',
    key: 'usersAndCompanies',
    access: { extra: true },
    children: [
      OPTIONS.ACCOUNT_MANAGEMENT,
      OPTIONS.DASHBOARD,
      OPTIONS.USERS,
      OPTIONS.COMPANIES,
      OPTIONS.TEAMS,
      OPTIONS.CALLS_LIST,
      OPTIONS.CALL_STATISTICS,
      OPTIONS.WHATS_APP,
    ],
  },
  {
    translateKey: 'logisticsMob',
    key: 'logisticsMob',
    access: { extra: true },
    children: [
      OPTIONS.CARRIERS,
      OPTIONS.LOGISTICS_REQUESTS,
      OPTIONS.CUSTOMERS,
      OPTIONS.WAREHOUSES,
      OPTIONS.CARS,
      OPTIONS.PAYMENT_ORDERS,
      OPTIONS.RATES,
      OPTIONS.ROUTES,
      OPTIONS.EXPORT,
    ],
  },
  {
    translateKey: 'documentsMob',
    key: 'documentsMob',
    access: { extra: true },
    children: [OPTIONS.SPECIFICATIONS, OPTIONS.DOCUMENTS_LIST, OPTIONS.CONTRACTS, OPTIONS.SAMPLES],
  },
]

export const useMobileOptions = () => {
  const filteredByPerms = useFilteredOptionsByPerms(MOBILE_OPTIONS)

  const eventsCount = useEventCount()
  const { menuOptions } = useMenuOptions(eventsCount, filteredByPerms, true)

  return { menuOptions }
}
