import { Progress } from '@agro-club/agroclub-shared'
import Chatra from '@chatra/chatra'
import addChatraUserUnfo from 'modules/domain/auth/addChatraUserUnfo'
import { clearToken, getAccessToken } from 'modules/domain/auth/repository'
import { UserProfile } from 'modules/domain/auth/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { createContext, useCallback, useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { Sentry } from 'sentry'
import AuthRoutes from 'views/pages/Auth/routes'

interface AuthProps {
  isAuthenticated: boolean
  progress: Progress
  profile?: UserProfile
  onLogin: () => Promise<void>
  logOut: () => Promise<void>
}

export const AuthContext = createContext<AuthProps>({
  isAuthenticated: false,
  progress: Progress.IDLE,
  onLogin: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
  logOut: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
})

export function AuthProvider({ children }: any) {
  const [profile, setProfile] = useState<UserProfile>()
  const navigate = useNavigate()
  const isAuthenticated = !!profile
  const [progress, setProgress] = useState<Progress>(Progress.WORK)

  const onLogin = useCallback(async () => {
    setProgress(Progress.WORK)
    const profile = await apiClient.get<UserProfile>(endpoints.profile())
    setProfile(profile)
    addChatraUserUnfo(profile)
    Sentry.sentryInstance?.setUser({ phone: profile.phone, id: profile.id })
    setProgress(Progress.SUCCESS)
  }, [])

  const logOut = useCallback(async () => {
    clearToken()
    setProfile(undefined)
    Chatra('kill')
    setProgress(Progress.IDLE)
    navigate(
      { pathname: generatePath(AuthRoutes.Login) },
      { state: { from: window.location.pathname + window.location.search } },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const accessToken = getAccessToken()
    if (accessToken) {
      onLogin()
    } else {
      setProgress(Progress.IDLE)
      navigate(
        { pathname: generatePath(AuthRoutes.Login) },
        { state: { from: window.location.pathname + window.location.search } },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLogin])

  return (
    <AuthContext.Provider value={{ isAuthenticated, profile, onLogin, logOut, progress }}>
      {children}
    </AuthContext.Provider>
  )
}
