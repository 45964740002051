import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { Dispatch, FC, SetStateAction } from 'react'
import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'
import { useColumns } from './useColumns'
import { AccountManagement } from 'modules/domain/accountManagement/types'

type Props = {
  listRequestParams: ListRequestParams
  listParamsUpdated: Dispatch<SetStateAction<ListRequestParams>>
  isFiltersLoaded: boolean
}

export const AccountManagementTable: FC<Props> = ({ listRequestParams, listParamsUpdated, isFiltersLoaded }) => {
  const columns = useColumns()

  const { progress, data, total, pageSize } = useTableData<AccountManagement>(
    endpoints.dashboardCallsUS(),
    listRequestParams,
    isFiltersLoaded,
  )

  return (
    <ATableFullPageWithoutData
      columns={columns}
      rowHoverGray
      listRequestParams={listRequestParams}
      listParamsUpdated={listParamsUpdated}
      data={data}
      progress={progress}
      total={total}
      pageSize={pageSize}
    />
  )
}
