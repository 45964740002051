import { FC, useContext } from 'react'

import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'

import { HelmetContext, Progress } from '@agro-club/agroclub-shared'

import useLanguage from 'hooks/useLanguage'
import { NotificationGlobalStyle } from 'hooks/useNotificationProgress'
import { AuthContext } from 'modules/context/AuthContext'
import { useTranslation } from 'react-i18next'
import PrivatePageWrapper from 'views/components/PageWrapper/PrivatePageWrapper'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import AuthPage from 'views/pages/Auth/AuthPage'
import { NotPermitted } from 'views/pages/Auth/NotPermitted'
import AuthRoutes from 'views/pages/Auth/routes'
import NotFound from 'views/pages/NotFound'
import CommonRoutes from 'views/pages/commonRoutes'
import { CoordinatorsRoutes } from './components/CoordinatorsRoutes'

const Wrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { helmet } = useContext(HelmetContext)

  return (
    <>
      <NotificationGlobalStyle />
      <Helmet defaultTitle="Agro.Club">
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
      </Helmet>
      {children}
    </>
  )
}

const RootComponent: FC<any> = () => {
  const { progress: profileProgress } = useContext(AuthContext)

  // workaround before useSuspense, hint how to get this array:
  // ls -1 | awk -F "." '{print $1}'| awk 'NF { print "\""$0"\","}'
  const { ready: traslatesIsReady } = useTranslation([
    'accountManagement',
    'address',
    'auth',
    'bid',
    'bidContracts',
    'calculator',
    'callStatistics',
    'calls',
    'car',
    'carTrips',
    'carrier',
    'comments',
    'common',
    'company',
    'contracts',
    'dashboard',
    'deal',
    'dispatching',
    'documentRequests',
    'errors',
    'executors',
    'fieldsLabels',
    'warehouses',
    'legalType',
    'logisticBids',
    'logistics',
    'logisticsDetail',
    'logisticsKR',
    'logisticsRightPanel',
    'menu',
    'mobileMenu',
    'my_tasks',
    'newUsers',
    'popover',
    'rates',
    'regionFilter',
    'routes',
    'samples',
    'shipments',
    'shipmentsControl',
    'specification',
    'tableNoDataText',
    'task',
    'teams',
    'tripPages',
    'trips',
    'ui',
    'user',
    'userCalls',
    'userContact',
    'validation',
    'vehicles',
    'warehouse',
    'whatsApp',
    'paymentOrders',
  ])
  const lang = useLanguage()

  if (profileProgress === Progress.WORK || !traslatesIsReady) {
    return (
      <Wrapper>
        <SpinnerLayout />
      </Wrapper>
    )
  }

  return (
    <Wrapper key={lang}>
      <Routes>
        <Route path={AuthRoutes.Login} element={<AuthPage />} />
        <Route path={AuthRoutes.NotPermitted} element={<NotPermitted />} />
        <Route path={CommonRoutes.NotFound} element={<NotFound />} />
        <Route path="/" element={<PrivatePageWrapper />}>
          <Route path="*" element={<CoordinatorsRoutes />} />
        </Route>
      </Routes>
    </Wrapper>
  )
}

export default RootComponent
