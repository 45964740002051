import { Addresses } from './Addresses/AddressesBlock'
import { Documents } from './Documents/Documents'
import { UserTasks } from './UserTasks/TaskBlock'
import { UserData } from './UserInfo/UserData'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { AntCollapse, Panel, Progress } from '@agro-club/agroclub-shared'
import { RightPanelComments } from 'views/components/Comments/styled'
import { Files } from './Files/Files'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { ProfileType, User } from 'modules/domain/user/types'
import { CommentType } from 'modules/domain/comment/types'
import { UserRating } from './UserRating/UserRating'
import { useTranslation } from 'react-i18next'
import { Contacts } from './Contacts/Contacts'
import { endpoints } from 'modules/endpoints'
import { FC, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Samples } from './Samples/Samples'
import { WrapperCollapse } from './styled'
import { Calls } from './Calls/Calls'
import { isBrazil, isRussia, isUS } from 'env'
import { Acts } from './Acts'
import { AuthContext } from 'modules/context/AuthContext'
import { TaskManagerReturnType } from './useUserTasksManager'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import AComments from 'views/components/Comments/AComments'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryCount } from 'views/components/AntCollapseHeader/SummaryCount'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'
import { Offers } from './Offers/Offers'
import { UserAddress } from 'modules/domain/userAddress/types'
import { Notifications } from './Notifications/Notifications'
import { SmsHistory } from './SmsHistory/SmsHistory'

type UserRightBlockProps = {
  userRefetch: refetchFunc
  taskManager: TaskManagerReturnType
  user: User
  openTaskModal: () => void
}

type OptionType = {
  title: string
  value: string
  isOpened?: boolean
  isShow?: boolean
  progress?: Progress
  loadOnDemand?: boolean
}[]

export const UserRightBlock: FC<UserRightBlockProps> = ({ user, userRefetch, openTaskModal, taskManager }) => {
  const isSeller =
    user.profile?.profile_type === ProfileType.seller || user.profile?.profile_type === ProfileType.manager
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams()

  const [countCalls, setCountCalls] = useState<number | undefined>()
  const [commentsSummary, setCommentsSummary] = useState<string>()
  const [docsSummary, setDocsSummary] = useState<number>()
  const [addressSummary, setAddressSummary] = useState<number>()
  const [contactsSummary, setContactsSummary] = useState<number>()
  const [filesSummary, setFilesSummary] = useState<number>()
  const [samplesSummary, setSamplesSummary] = useState<number>()
  const [countActs, setCountActs] = useState<number | undefined>()

  const { profile } = useContext(AuthContext)
  const [progress, addresses = [], addressesRefetch] = useSingleEntity<UserAddress[]>(endpoints.userAddress(user.id))
  const isRatingVisible = profile?.permissions.includes('deals.view_rating')
  const options = useMemo<OptionType>(
    () =>
      [
        { value: 'comments', title: t('common:comments'), isOpened: true, isShow: true },
        { value: 'offers', title: t('user:showPrices'), isShow: isUS && isSeller, loadOnDemand: true },
        { value: 'tasks', title: t('task:tasks'), progress: taskManager.progress, isShow: true },
        { value: 'user', title: t('user'), isShow: true },
        { value: 'rating', title: t('rating'), isShow: isRatingVisible && isSeller && !isBrazil },
        { value: 'documents', title: t('common:documents'), isShow: !isUS },
        { value: 'acts', title: t('common:reconciliation_acts'), isShow: !!user.profile?.company && isRussia },
        { value: 'addresses', title: t('common:addresses'), isShow: true },
        { value: 'notifications', title: t('user:smsNotification.notifications'), isShow: isUS },
        { value: 'smsHistory', title: t('user:smsHistory'), isShow: isUS },
        { value: 'contacts', title: t('common:contacts'), isShow: true },
        { value: 'samples', title: t('samples:samples'), isShow: isSeller },
        { value: 'calls', title: t('common:calls'), isShow: !isUS },
        { value: 'files', title: t('common:files'), isShow: isRussia },
      ].filter((option) => option.isShow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isSeller, isRatingVisible, taskManager.progress],
  )

  const components = {
    comments: (
      <RightPanelComments>
        <AComments
          url={endpoints.userComments(id)}
          popSummary={setCommentsSummary}
          type={CommentType.user}
          maxHeight={400}
          id={id}
        />
      </RightPanelComments>
    ),
    offers: <Offers progress={progress} addresses={addresses} addressesRefetch={addressesRefetch} />,
    tasks: <UserTasks taskManager={taskManager} openTaskModal={openTaskModal} user={user} userRefetch={userRefetch} />,
    user: <UserData user={user} userRefetch={userRefetch} />,
    rating: <UserRating user={user} />,
    documents: <Documents userId={id} setSummary={setDocsSummary} />,
    acts: user.profile?.company && <Acts companyId={user.profile?.company.id} setCountActs={setCountActs} />,
    addresses: (
      <Addresses
        user={user}
        setSummary={setAddressSummary}
        progress={progress}
        addresses={addresses}
        addressesRefetch={addressesRefetch}
      />
    ),
    notifications: <Notifications refetch={userRefetch} user={user} />,
    smsHistory: <SmsHistory user={user} />,
    files: <Files setSummary={setFilesSummary} />,
    contacts: <Contacts setSummary={setContactsSummary} user={user} userRefetch={userRefetch} />,
    samples: <Samples setSummary={setSamplesSummary} userId={user.id} />,
    calls: <Calls setCount={setCountCalls} count={countCalls} />,
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    tasks: <SummaryCount total={taskManager.total} />,

    user: <div>{user.profile && `${t(`profile_type.${user.profile?.profile_type}`)}`}</div>,
    documents: <SummaryThereIsNo hasData={docsSummary} />,

    acts: <SummaryCount total={countActs} />,
    addresses: <SummaryCount total={addressSummary} />,

    files: <SummaryCount total={filesSummary} />,
    contacts: <SummaryCount total={contactsSummary} />,
    calls: <SummaryThereIsNo hasData={countCalls} />,
    samples: <SummaryCount total={samplesSummary} />,

    rating: <div>{formatNumber(user?.rating, true, { fractionDigits: 1 })}</div>,
  }
  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)

  return (
    <WrapperCollapse>
      <AntCollapse
        onChange={(key) => setActiveKey(key)}
        defaultActiveKey={options[0].value}
        style={{ width: 566 }}
        bordered={false}
        accordion
      >
        {options.map((option) => {
          return (
            <Panel
              forceRender={!option.loadOnDemand}
              key={option.value}
              header={
                <AntCollapseHeader
                  progress={option.progress}
                  title={option.title}
                  summary={activeKey !== option.value && summary[option.value]}
                />
              }
            >
              {components[option.value]}
            </Panel>
          )
        })}
      </AntCollapse>
    </WrapperCollapse>
  )
}
