import { Button, ButtonFullProps, defaultTheme, Icons, Text, Typography } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { box, boxBordered } from 'views/styled/common'

type BackgroundIntent = 'default' | 'primary' | 'description' | 'warning' | 'tertiary' | 'backgroundPrimary'
type Padding = 'compact' | 'normal' | '8px'
type BorderRadius = 'normal' | 'large'

type ContainerProps = {
  intent?: BackgroundIntent
  bordered?: boolean
  disableShadow?: boolean
  padding?: Padding
  borderRadius?: BorderRadius
}

const borderRadiusVariables = css<ContainerProps>`
  border-radius: ${({ borderRadius }) => {
    switch (borderRadius) {
      case 'normal':
        return '8px'
      case 'large':
        return '16px'
      default:
        return '8px'
    }
  }};
`

const containerVariety = css<ContainerProps>`
  ${({ intent = 'default', bordered, disableShadow }) => {
    let containerIntent
    switch (intent) {
      case 'primary':
        containerIntent = css`
          background-color: ${defaultTheme.color.primary8a};
          border-color: ${defaultTheme.color.primary16a};
        `
        break
      case 'description':
        containerIntent = css`
          background-color: ${defaultTheme.color.accentNotify16a};
          border-color: ${defaultTheme.color.accentNotify800};
        `
        break
      case 'warning':
        containerIntent = css`
          background-color: ${defaultTheme.color.accentNotify100};
          border-color: ${defaultTheme.color.accentNotify100};
        `
        break
      case 'tertiary':
        containerIntent = css`
          background-color: ${defaultTheme.color.backgroundTertiary};
        `
        break
      case 'backgroundPrimary':
        containerIntent = css`
          background-color: ${defaultTheme.color.backgroundPrimary};
        `
        break
      default:
        containerIntent = css`
          background-color: ${defaultTheme.color.backgroundSecondary};
          border-color: ${defaultTheme.color.secondary50};
        `
        break
    }

    return css`
      ${bordered ? boxBordered : box}
      ${containerIntent}
      ${disableShadow &&
      css`
        box-shadow: none;
      `}
      ${borderRadiusVariables}
    `
  }}
`

const commonContainerStyles = css<ContainerProps>`
  width: 100%;
  position: relative;
  height: fit-content;
  padding: ${({ padding }) => {
    switch (padding) {
      case 'compact':
        return '11px 12px'
      case '8px':
        return '8px'
      default:
        return '16px'
    }
  }};
  ${Typography.bodyLarge};
`

const Grid = styled.div<{ cols?: number; maxColumnWidth?: string; columnGap?: number; rowGap?: number }>`
  ${({ cols, maxColumnWidth }) => css`
    display: grid;
    grid-template-columns: repeat(${cols || 1}, ${maxColumnWidth || '1fr'});
  `}
  gap: ${({ columnGap, rowGap }) => `${rowGap ?? 12}px ${columnGap ?? 16}px`};
`

const Container = styled.div<ContainerProps>`
  ${containerVariety};
  ${commonContainerStyles};
  display: flex;
  flex-direction: column;

  & & {
    box-shadow: none;
  }
`

const GapableContent = styled.div<{ noPaddingForEmpty?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  & > div:empty {
    margin-top: ${({ noPaddingForEmpty = true }) => (noPaddingForEmpty ? '-12px' : 'none')};
  }

  & + & {
    margin-top: 24px;
  }
`

const MultiContainers = styled.div<ContainerProps>`
  ${containerVariety};
  ${commonContainerStyles};

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & & {
    box-shadow: none;
  }

  & + & {
    border-top: none;
  }
`

const StyledEditButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 12px;
`

type CardEditButtonProps = Omit<ButtonFullProps, 'id' | 'onClick' | 'intent'> & {
  id?: string
  onClick: () => void
}

const EditButton: FC<CardEditButtonProps> = ({ id = 'edit', ...otherProps }) => {
  return <StyledEditButton id={id} intent="white" Icon={Icons.IconEdit} {...otherProps} />
}

const HorizontalDivider = styled.hr<{ noMargin?: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? '0' : '16px -16px')};
  background-color: ${defaultTheme.color.secondary50};
  height: 1px;
  border: none;
`

const VerticalDivider = styled.hr`
  margin: -16px 16px;
  border: none;
  border-left: 1px solid ${defaultTheme.color.secondary16a};
`

const Title = styled(Text).attrs({ typography: 'titleH4', color: 'primary' })``

export type GridItem<T = string> = {
  label: T
  value: T
}

export const Card = {
  Container,
  GapableContent,
  MultiContainers,
  Title,
  HorizontalDivider,
  VerticalDivider,
  EditButton,
  Grid,
}
