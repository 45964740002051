import { TitleWithButtons } from 'views/pages/Deal/DealDetail/styled'
import { Card } from 'views/components/Card/Card'
import { AntTag, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { isDealOpen } from 'modules/domain/deal/utils'
import { TooltipIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { isStatusAfter } from 'views/pages/Deal/DealDetail/helpers'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { AButton } from 'views/components/Analytics'
import { DealCopyText } from 'views/pages/Deal/DealDetail/DealCopyText'
import { isUS } from 'env'
import { DealDetailEditModal } from 'views/pages/Deal/DealDetail/DealEditModals/DealDetailEditModal'
import { DealHistoryModal } from 'views/pages/Deal/DealDetail/DealHistoryModal'
import { FC, useState } from 'react'
import { generatePath } from 'react-router-dom'
import LogisticRequestsRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDealContext } from '../DealContext'
import { GeneralInfoContentUS } from './GeneralInfoContentUS'
import { GeneralInfoContent } from './GeneralInfoContent'
import { useMediaQuery } from 'react-responsive'
import { BrokerInfo } from './BrokerInfo'

const DetailTitleWithTag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const DetailButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
const Wrapper = styled.div`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    padding: 16px;
  }
`

const Tittle = styled(Card.Title)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    text-align: center;
  }
`
export const GeneralInfo: FC = () => {
  const { t } = useTranslation('deal')
  const { deal, dealRefetch } = useDealContext()

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false)
  const requestDetailsPath = deal?.logistics_request_id
    ? generatePath(LogisticRequestsRoutes.Details, { id: deal.logistics_request_id })
    : undefined

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <Wrapper>
      <Card.GapableContent>
        <>
          <TitleWithButtons>
            <DetailTitleWithTag>
              {!isMobile && <Tittle>{t('dealDetail')}</Tittle>}
              <AntTag color={'white'}>{t(`dealTypes.${deal.deal_type}`)}</AntTag>
            </DetailTitleWithTag>
            {isDealOpen(deal) && (
              <DetailButtonsWrapper>
                <TooltipIcon isVisible={!!requestDetailsPath} text={t('logisticRequests')}>
                  <AButton
                    // TODO make better height calculation
                    style={{ height: '32px' }}
                    intent="white"
                    Icon={Icons.IconShipping}
                    to={requestDetailsPath}
                    id="logistics"
                  />
                </TooltipIcon>

                <TooltipIcon
                  isVisible={isStatusAfter(deal.status, DealStatusTypes.CONTRACT_SIGNED)}
                  text={t('history')}
                >
                  <AButton
                    size="medium"
                    intent="white"
                    Icon={Icons.IconHistory}
                    onClick={() => setHistoryModalIsOpen(true)}
                    id="history"
                  />
                </TooltipIcon>

                <TooltipIcon isVisible text={t('editDeal')}>
                  <AButton
                    id="edit"
                    size="medium"
                    intent="white"
                    Icon={Icons.IconEdit}
                    onClick={() => setEditModalIsOpen(true)}
                  />
                </TooltipIcon>

                <DealCopyText deal={deal} />
              </DetailButtonsWrapper>
            )}
          </TitleWithButtons>
          <BrokerInfo />
          {isMobile && <Tittle>{t('dealDetail')}</Tittle>}
        </>
        {isUS ? <GeneralInfoContentUS /> : <GeneralInfoContent />}
      </Card.GapableContent>
      {editModalIsOpen && (
        <DealDetailEditModal deal={deal} onClose={() => setEditModalIsOpen(false)} refetch={dealRefetch} />
      )}
      {historyModalIsOpen && <DealHistoryModal deal={deal} close={() => setHistoryModalIsOpen(false)} />}
    </Wrapper>
  )
}
