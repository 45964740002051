import { useHelmet } from '@agro-club/agroclub-shared'
import { useFilters } from 'hooks/useFilter'
import { ListRequestParams } from 'modules/domain/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { AccountManagementFilters } from './AccountManagementFilters'
import { AccountManagementTable } from './AccountManagementTable/AccountManagementTable'

export const ACC_MANAGEMENT_PAGE_SIZE = 500

export const AccountManagement: FC = () => {
  const { t } = useTranslation('accountManagement')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: ACC_MANAGEMENT_PAGE_SIZE,
  })

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  useHelmet({ title: t('title') })
  return (
    <APageWrapper page="accountManagement" place="AccountManagement">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('title'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('title')}</Layout.PageName>
          <AccountManagementFilters
            listRequestParams={listRequestParams}
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>

        <AccountManagementTable
          listRequestParams={listRequestParams}
          listParamsUpdated={setListRequestParams}
          isFiltersLoaded={isFiltersLoaded}
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
