import { BidPrice, BidTypes } from 'modules/domain/bid/types'

export const isDeliveryAddressRequired = (bidType: BidTypes, price_type?: BidPrice) => {
  if (bidType == BidTypes.sale && price_type === BidPrice.CPT) {
    return true
  } else if (bidType == BidTypes.purchase && price_type === BidPrice.EXW) {
    return true
  }
  return false
}
