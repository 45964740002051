import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import { isVisibleCredit } from 'env'
import { t } from 'i18next'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatTurnoverDays } from '../helpers'
import useFormatDate from 'hooks/useFormatDate'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type Props = {
  plan?: number
  actual?: number
  planDate?: string
  actualDate?: string
}

const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`

export const TurnoverDays: FC<Props> = ({ plan, actual, planDate, actualDate }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  if (!isVisibleCredit) return null

  return (
    <Card.Container bordered>
      <Card.Grid cols={2} maxColumnWidth={isMobile ? '1fr' : '186px'} columnGap={32}>
        <KeyValue label={t('deal:turnoverDaysPlan')}>
          <TextContainer>
            {formatTurnoverDays(plan)}
            {planDate && <TurnoverDate date={planDate} />}
          </TextContainer>
        </KeyValue>
        <KeyValue label={t('deal:turnoverDaysActual')}>
          <TextContainer>
            {formatTurnoverDays(actual)}
            {actualDate && <TurnoverDate date={actualDate} />}
          </TextContainer>
        </KeyValue>
      </Card.Grid>
    </Card.Container>
  )
}

const TurnoverDate: FC<{ date?: string }> = ({ date }) => {
  const { t } = useTranslation('common')
  const formatDate = useFormatDate()
  return (
    <Text typography="bodySmall" color="secondary">
      ({t('updatedShort')} {formatDate(date, true)})
    </Text>
  )
}
