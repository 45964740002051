import { AvailableLanguages, FormGroup, GoogleAddressForm, YandexAddressFormV2 } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import env, { isBrazil } from 'env'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik?: FormikFieldsType
  handlerMap: (data: any) => void
}

const centerUS = { lat: 38.9072, lng: -77.0369 }

export const centerBR = {
  lat: -15.7975154,
  lng: -47.8918873,
}

export const getGoogleOptions = () => {
  const centerMap = isBrazil ? centerBR : centerUS
  return {
    searchOptions: {
      componentRestrictions: { country: isBrazil ? 'br' : null },
    },
    language: isBrazil ? AvailableLanguages.pt_BR : AvailableLanguages.en,
    defaultCenter: centerMap,
    hideCoordsLabel: true,
  }
}

export const FormMap: FC<Props> = ({ formik, handlerMap }) => {
  const { t } = useTranslation('user')
  const [valueMaps, setValueMaps] = useState<string | undefined>()
  const yandexKey = env.REACT_APP_YA_MAPS_API_KEY
  const yandexSuggestKey = env.REACT_APP_YA_MAPS_SUGGEST_API_KEY
  const googleKey = env.REACT_APP_GOOGLE_MAPS_API_KEY

  return (
    <>
      {env.REACT_APP_USE_GOOGLE_MAPS ? (
        <FormGroup label={t('form.fields.address')} error={formik && formik.errors.addresses?.[0].address}>
          <GoogleAddressForm {...getGoogleOptions()} onChange={handlerMap} apikey={googleKey} size="middle" />
        </FormGroup>
      ) : (
        <FormGroup label={t('form.fields.address')} error={formik && formik.errors.addresses}>
          <YandexAddressFormV2
            suggest_apikey={yandexSuggestKey}
            onChange={handlerMap}
            setValue={setValueMaps}
            value={valueMaps}
            apikey={yandexKey}
            size="middle"
          />
        </FormGroup>
      )}
    </>
  )
}
