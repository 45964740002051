import { isBrazil, isRussia } from 'env'
import { FormikFieldsType } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { ResponsiveSelectAddButton } from 'views/components/FormSelectButtons/FormSelectButtons'
import { AddAddressModal } from 'views/pages/User/UserDetail/UserRightPanel/Addresses/AddAddressModal'
import { AddressBrazil } from './AddressBrazil'
import { AddressCommon } from './AddressCommon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`

export type PropsFormAddress = {
  formik: FormikFieldsType
  required?: boolean
  disabled?: boolean
  fieldName?: string
  label?: string
  userId: string
  companyId: string
}

export const FormAddress: FC<PropsFormAddress> = ({
  fieldName = 'address',
  required,
  userId,
  formik,
  label,
  disabled,
  companyId,
}) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => setShowModal(!showModal)
  const handleAddAddress = (id) => formik.setFieldValue(fieldName, String(id))

  const AddressComponent = isBrazil ? AddressBrazil : AddressCommon
  const endpoint = isRussia ? endpoints.companyAddress(companyId, userId) : endpoints.companyAddress(companyId)

  return (
    <Wrapper>
      <AddressComponent
        endpoint={endpoint}
        fieldName={fieldName}
        required={required}
        formik={formik}
        label={label}
        disabled={disabled}
      >
        <ResponsiveSelectAddButton action={toggleModal} eventId="openAddAddressModal" />
      </AddressComponent>
      {showModal && (
        <AddAddressModal onClose={toggleModal} onSuccess={handleAddAddress} userId={userId} companyId={companyId} />
      )}
    </Wrapper>
  )
}

export default FormAddress
