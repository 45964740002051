import { FC } from 'react'

import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { Bid, BidEditableFields } from 'modules/domain/bid/types'
import { FormGroup, Input, Progress, Spinner, useHelmet } from '@agro-club/agroclub-shared'
import { Product } from 'modules/domain/collection/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { ShortFormControl, SpinnerWrapper } from 'views/styled/common'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { PricePeriodsForm } from '../../components/Futures'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { BidParamsInputs } from './BidParamsInputs'
import { useFormik } from 'formik'
import { isBidProductParamsEnabled } from 'env'
import { SeasonSelect } from '../../components/SeasonSelect'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { labelTarif } from 'modules/utils/numbers/formatTarif'

type Props = {
  onClose: () => void
  onSuccess?: (arg: boolean) => void
  bid: Bid
}

export const BidEditModal: FC<Props> = ({ onClose, onSuccess, bid }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('bid')
  const [productProgress, product] = useSingleEntity<Product>(endpoints.productById(bid.product.id))

  useHelmet({ title: `${t('form.edit')} - ${product?.title}` })

  const mainParam = bid.parameters.find((p) => p.parameter.id === product?.main_parameter?.id)

  const formik = useFormik<BidEditableFields>({
    initialValues: {
      price: bid.price,
      quantity: bid.quantity,
      mainParameter: {
        parameter: mainParam?.parameter.id,
        parameter_value_from: mainParam?.parameter_value_from,
        parameter_value_to: mainParam?.parameter_value_to,
      },
      parameters:
        bid.parameters
          .filter((p) => p.parameter.id !== product?.main_parameter?.id)
          .map((p) => ({
            parameter: p.parameter.id,
            parameter_value_from: p?.parameter_value_from,
            parameter_value_to: p?.parameter_value_to,
          })) || [],
      price_periods: [...(bid.price_periods ?? [])],
      season: bid.season?.id,
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        const { quantity, price, mainParameter, parameters, price_periods, season } = formik.values
        const dto = {
          quantity,
          price,
          parameters: isBidProductParamsEnabled ? [mainParameter, ...parameters] : [],
          price_periods: price_periods?.length ? price_periods : undefined,
          season,
        }

        return await apiClient.put(endpoints.bid(bid.id), dto)
      },
      {
        onSuccess: async () => {
          await onSuccess?.(true)
          onClose()
        },
        onError: (error: RequestError) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  if (productProgress == Progress.WORK) {
    return (
      <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.EDIT_MODAL}>
        <SharedModalTitle>{t('form.bidsParams')}</SharedModalTitle>
        <SharedModalContent>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </SharedModalContent>
      </ASharedModal>
    )
  }
  const isPricePeriodsBid = isBidWithPricePeriods(bid)

  return (
    <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.EDIT_MODAL}>
      <SharedModalTitle>{t('form.bidsParams')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <SeasonSelect formik={formik} product_id={bid.product.id} />
          {!isPricePeriodsBid && (
            <ShortFormControl>
              <FormGroup label={labelTarif(t('common:price.default'))} error={formik.errors.price} required>
                <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type={'number'} />
              </FormGroup>
            </ShortFormControl>
          )}
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
          </ShortFormControl>
        </SharedModalInputs>

        {isPricePeriodsBid && <PricePeriodsForm formik={formik} priceLabel={labelTarif(t('common:price.default'))} />}
        <BidParamsInputs formik={formik} product={product} />
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.handleSubmit()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
