import { Icons, Select, Text } from '@agro-club/agroclub-shared'
import { AccountManagementContact } from 'modules/domain/accountManagement/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`

type Props = {
  contacts: AccountManagementContact[]
  companyId: string
}

export const PhoneSelectWithCall: FC<Props> = ({ contacts, companyId }) => {
  const { t } = useTranslation('common')
  const options = useMemo(
    () => contacts?.map((contact) => ({ label: contact.phone, value: contact.phone })),
    [contacts],
  )
  const [phone, setPhone] = useState<string>(options?.[0]?.label)

  const sendCallLogs = () => apiClient.post(endpoints.dashboardCallLogs(), { client_phone: phone, company: companyId })

  return (
    <Wrapper>
      {options.length ? (
        <>
          <Select
            onChange={(phone) => setPhone(phone)}
            size="small"
            fullWidth
            value={phone}
            options={options}
            allowClear={false}
            showSearch={false}
            OptionComponent={({ option }) => <Text typography="bodyMedium">{option.label}</Text>}
          />
          <AButton id="call" Icon={Icons.IconPhone} color="secondary" href={`tel:${phone}`} onClick={sendCallLogs} />
        </>
      ) : (
        <>{t('noNumber')}</>
      )}
    </Wrapper>
  )
}
