import { useMemo } from 'react'
import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import formatPrice from 'modules/utils/numbers/formatPrice'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatDateWithoutLocale from 'helpers/formatDateWithoutLocale'
import { RU_DATE_FORMAT } from 'modules/constants'
import { DispatchingStatusesTags } from 'views/components/DispatchingStatusesTags/DispatchingStatusesTags'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { getColors, renderPaymentsCell } from './helpers'
import { TFunction } from 'i18next'
import { TripsLinkCell } from './components/TripsLinkCell'
import { DealLink } from 'views/components/DealLink/DealLink'
import { SpecificationLink } from './components/SpecificationLink'
import { renderCompanyDetails } from './useColumns'
import { CommentsCell } from 'views/pages/Dispatching/components/CommentsCell/CommentsCell'
import { ListRequestParams } from 'modules/domain/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'

const getColumns = (
  datesArray: string[],
  t: TFunction,
  setDrawerIsOpen: (arg: boolean) => void,
  setSelectedDealId: (value: string) => void,
  sortParam: ListRequestParams['sort'],
) => {
  const currency = REACT_APP_CURRENCY_SYMBOL
  const datesColumns = datesArray
    ? datesArray.map((date) => ({
        key: date,
        title: `${formatDateWithoutLocale(date, 'dd.MM')}, ${currency}`,
        width: 150,
        align: 'right',
        dataIndex: 'payments',
        render: (payments) => {
          return renderPaymentsCell(payments, date, t)
        },
        onCell: (record) => {
          const colors = getColors(record.payments[date]?.status)
          return { style: { backgroundColor: colors.background } }
        },
      }))
    : []

  const results = [
    {
      title: t('table.product'),
      width: 160,
      dataIndex: 'product_title',
      render: (product_title, data) => (
        <DealLink dealId={data.deal_id} bodysize="bodyRegular" target="_blank">
          {product_title}
        </DealLink>
      ),
      fixed: 'left',
    },
    {
      title: t('table.sellerAndCustomer'),
      width: 215,
      dataIndex: 'customer',
      sorter: true,
      sortOrder: convertSortingToAntDFormat('customer', sortParam),
      render: (customer, data) => (
        <>
          {renderCompanyDetails(data.seller, data.seller_address_title)}
          {renderCompanyDetails(customer, data.customer_address_title)}
        </>
      ),
      fixed: 'left',
    },
    {
      title: t('table.status'),
      width: 160,
      dataIndex: 'status',
      render: (status) => <DispatchingStatusesTags status={status} />,
    },
    {
      title: t('table.price_exw', { currency }),
      width: 72,
      align: 'right',
      dataIndex: 'price_exw',
      render: (price_exw) => formatPrice(price_exw),
    },
    {
      title: t('table.cost', { currency }),
      width: 130,
      align: 'right',
      dataIndex: 'cost',
      render: (cost) => formatNumber(cost),
    },
    {
      title: t('table.quantity'),
      width: 94,
      align: 'right',
      dataIndex: 'quantity',
      render: (quantity) => formatNumber(quantity, true),
    },
    {
      title: t('table.quantity_paid_for'),
      width: 105,
      align: 'right',
      dataIndex: 'quantity_paid_for',
      render: (quantity_paid_for) => formatNumber(quantity_paid_for, true),
    },
    {
      title: t('table.loaded_quantity'),
      width: 110,
      align: 'right',
      dataIndex: 'loaded_quantity',
      render: (loaded_quantity) => formatNumber(loaded_quantity, true),
    },
    {
      title: t('table.delivered_quantity'),
      width: 110,
      align: 'right',
      dataIndex: 'delivered_quantity',
      render: (delivered_quantity) => formatNumber(delivered_quantity, true),
    },
    {
      title: t('table.remaining_quantity'),
      width: 100,
      align: 'right',
      dataIndex: 'remaining_quantity',
      render: (remaining_quantity) => formatNumber(remaining_quantity, true),
    },
    {
      title: t('table.planned_remaining_quantity'),
      width: 150,
      align: 'right',
      dataIndex: 'planned_remaining_quantity',
      render: (planned_remaining_quantity) => formatNumber(planned_remaining_quantity, true),
    },
    {
      title: t('table.trips_today'),
      width: 126,
      align: 'right',
      dataIndex: 'trips_today',
      render: (trips_today, data) => (
        <TripsLinkCell value={trips_today} logisticsRequestId={data.logistics_request_id} />
      ),
    },
    {
      title: t('table.trips_tomorrow'),
      width: 120,
      align: 'right',
      dataIndex: 'trips_tomorrow',
      render: (trips_tomorrow, data) => (
        <TripsLinkCell value={trips_tomorrow} logisticsRequestId={data.logistics_request_id} />
      ),
    },
    {
      title: t('table.trips_norm_percent'),
      width: 160,
      dataIndex: 'trips_norm_percent',
      align: 'right',
      render: (trips_norm_percent) => `${trips_norm_percent}%`,
    },
    {
      title: t('table.logisticians'),
      width: 260,
      dataIndex: 'logistician',
      render: (logistician) => (logistician?.full_name ? logistician.full_name : null),
    },
    {
      title: t('table.comments'),
      width: 280,
      dataIndex: 'comments',
      render: (comments, data) => (
        <CommentsCell
          comments={comments}
          dealId={data.deal_id}
          setDrawerIsOpen={setDrawerIsOpen}
          setSelectedDealId={setSelectedDealId}
        />
      ),
    },
    {
      title: t('table.specification_deadline'),
      width: 100,
      dataIndex: 'purchase_specification_deadline',
      render: (purchase_specification_deadline, data) => (
        <SpecificationLink
          deadline={purchase_specification_deadline}
          id={data.purchase_specification_id}
          without_purchase_specification={data.without_purchase_specification}
        />
      ),
    },
    {
      title: t('table.documents_request_expected_complete_date'),
      width: 100,
      dataIndex: 'documents_request_expected_complete_date',
      render: (documents_request_expected_complete_date, data) => {
        if (data.without_documents_request) return t('common:without_docs')
        return formatDateWithoutLocale(documents_request_expected_complete_date, RU_DATE_FORMAT)
      },
    },
    ...datesColumns,
    {
      title: t('table.remaining_cost', { currency }),
      width: 130,
      align: 'right',
      dataIndex: 'remaining_cost',
      render: (remaining_cost) => formatNumber(remaining_cost),
    },
  ]

  return results
}

const useClosedColumns = (
  datesArray: string[],
  data: object[],
  onSuccess: () => void,
  setDrawerIsOpen: (arg: boolean) => void,
  setSelectedDealId: (value: string) => void,
  sortParam: ListRequestParams['sort'],
): ColumnData[] => {
  const { t } = useTranslation('dispatching')

  return useMemo(
    () => getColumns(datesArray, t, setDrawerIsOpen, setSelectedDealId, sortParam),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, datesArray, sortParam],
  )
}

export default useClosedColumns
