import { refetchFunc } from 'modules/domain/common/hooks'
import { ProfileType, User } from 'modules/domain/user/types'
import styled from 'styled-components'
import { VerifiedByCoordinator } from './VerifiedByCoordinator'
import { KeyUserVerification } from './KeyUserVerification'
import { VerifiedUser } from './VerifiedUser'
import { VerifiedBySecurity } from './VerifiedBySecurity'
import { BlockUserButton } from './BlockUserButton'
import { KycVerify } from './KycVerify'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 165px);
  gap: 16px;
`

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const VerifyStatusList = ({ user, userRefetch }: Props) => {
  const canBeBidOwner = !![ProfileType.seller, ProfileType.customer, ProfileType.manager].find(
    (t) => t == user.profile?.profile_type,
  )
  const showVerifyList = canBeBidOwner && user.is_active

  return (
    <Wrapper>
      {showVerifyList && (
        <>
          {user.profile?.is_verified ? (
            <VerifiedUser
              coordinatorVerifiedDate={user.profile.verified_by_coordinator_at as string}
              securityVerifiedDate={user.profile.verified_by_security_at as string}
            />
          ) : (
            <>
              <VerifiedByCoordinator user={user} userRefetch={userRefetch} />
              <VerifiedBySecurity user={user} userRefetch={userRefetch} />
              <KycVerify user={user} userRefetch={userRefetch} />
            </>
          )}
          <KeyUserVerification user={user} userRefetch={userRefetch} />
        </>
      )}
      <BlockUserButton user={user} userRefetch={userRefetch} />
    </Wrapper>
  )
}
