import { ResponsesColumn } from './ResponsesColumn/ResponsesColumn'
import { DealReason, DealStatus } from './MatchesColumn/MatchCard'
import { MatchesColumn } from './MatchesColumn/MatchesColumn'
import { WrapperBoard } from 'views/components/Board/styled'
import { BidsColumn } from './BidsColumn/BidsColumn'
import { User } from 'modules/domain/user/types'
import { Bid } from 'modules/domain/bid/types'
import { FC, useState } from 'react'

type Props = {
  statuses: DealStatus[]
  reasons: DealReason[]
  user: User
}

export const Board: FC<Props> = ({ user, statuses, reasons }) => {
  const [activeBid, setActiveBid] = useState<Bid>()

  return (
    <WrapperBoard dividingBorder>
      <BidsColumn
        userId={user.id}
        userType={user.profile?.profile_type}
        setActiveBid={setActiveBid}
        activeBid={activeBid}
      />
      <MatchesColumn activeBid={activeBid} reasons={reasons} statuses={statuses} />
      <ResponsesColumn activeBid={activeBid} statuses={statuses} reasons={reasons} />
    </WrapperBoard>
  )
}
