import { Icons, Progress } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { refetchFunc, usePagination } from 'modules/domain/common/hooks'
import { Address } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import { ListFooterWrapper } from 'views/styled/common'
import { AddAddressModal } from './AddAddressModal'
import { AddressItem } from './AddressItem'
import { AddressesBlockWrapper } from './styled'

type Props = {
  setSummary: (arg: number) => void
  user: User
  addressesRefetch: refetchFunc
  addresses: Address[]
  progress: Progress
}

export const Addresses: FC<Props> = ({ user, setSummary, progress, addresses, addressesRefetch }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation('user')
  const [addressesCurrentPage, setAddressesCurrentPage, addressesCurrentItems] = usePagination(
    addresses,
    ACCORDION_ITEM_PAGE_SIZE,
  )
  const showRemoveIcon = addresses && addresses?.length > 1

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(addresses.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses.length])

  return (
    <>
      {addresses && (
        <AddressesBlockWrapper>
          {addressesCurrentItems.map((address) => (
            <AddressItem
              addressesRefetch={addressesRefetch}
              showRemoveIcon={showRemoveIcon}
              address={address}
              key={address.id}
              user={user}
            />
          ))}
        </AddressesBlockWrapper>
      )}
      <ListFooterWrapper>
        <AButton id="addAddress" Icon={Icons.IconAdd} intent="approve" size="medium" onClick={() => setShowModal(true)}>
          {t('form.addAddress')}
        </AButton>
        <Pagination
          disableMargin
          total={addresses.length}
          currPage={addressesCurrentPage}
          setCurrPage={setAddressesCurrentPage}
        />
      </ListFooterWrapper>

      {showModal && user.profile?.company?.id && (
        <AddAddressModal
          onClose={() => setShowModal(false)}
          userId={user.id}
          addressesRefetch={addressesRefetch}
          companyId={user.profile.company.id}
        />
      )}
    </>
  )
}
