import { ColumnsType } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { AccountManagement } from 'modules/domain/accountManagement/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { CallsComments } from './CommentsCell'
import { PhoneSelectWithCall } from './PhoneSelectWithCall'

export const useColumns = (): ColumnsType<AccountManagement> => {
  const { t } = useTranslation('common')

  return useMemo(
    () => [
      {
        title: `${t('company')}/${t('address')}`,
        dataIndex: 'company',
        autoWidth: 337,
        render: (_, data) => (
          <div>
            <TableTextLink id="company" to={generatePath(CompanyRoutes.Details, { id: data.id })}>
              {data?.name || DASH}
            </TableTextLink>
            <div>{data?.main_address?.address || DASH}</div>
          </div>
        ),
      },
      {
        title: t('contactName'),
        dataIndex: 'contacts',
        width: 200,
        render: (_, data) => (
          <>
            {data.contacts.length ? (
              <TableTextLink id="contact" to={generatePath(UserRoutes.Details, { id: data.contacts[0].user_id })}>
                {data.contacts[0].fullname}
              </TableTextLink>
            ) : (
              DASH
            )}
          </>
        ),
      },
      {
        title: t('phone'),
        dataIndex: 'phone',
        width: 232,
        render: (_, data) => <PhoneSelectWithCall contacts={data.contacts} companyId={data.id} />,
      },
      {
        title: t('lastContact'),
        dataIndex: 'last_comment_date',
        width: 162,
      },
      {
        title: t('days'),
        dataIndex: 'days_since_last_comment',
        align: 'right',
        sorter: true,
        width: 71,
      },
      {
        title: t('comments'),
        dataIndex: 'comments',
        width: 337,
        render: (comments) => <CallsComments lastComment={comments?.[0]?.comment} />,
      },
      {
        title: t('assignee'),
        dataIndex: 'responsible_coordinator',
        width: 168,
        render: (responsible_coordinator) => responsible_coordinator?.full_name,
      },
    ],
    [t],
  )
}
