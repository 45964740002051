import { useTranslation } from 'react-i18next'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { ARouterLink } from 'views/components/Analytics'
import { generatePath } from 'react-router'
import CompanyRoutes from 'views/pages/Company/routes'
import {
  CompanyFactoringIcon,
  KeyUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { Bid, BidTypes } from 'modules/domain/bid/types'
import UserRoutes from 'views/pages/User/routes'
import { getUserName, getUserPhone } from 'modules/domain/user/utils'
import APhone from 'views/ui/Phone/APhone'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { EntityWithIconsWrapper } from 'views/styled/common'
import { CompanyMembersTypes } from 'modules/domain/user/types'

type Props = {
  bid: Bid
}

export const BidDetailCompanyUser = ({ bid }: Props) => {
  const { t } = useTranslation('common')
  const companyPersonalNumber = `${getPersonalNumberLabel()}: ${bid?.company?.inn || t('common:notSpecified')}`
  const userBidLink = generatePath(UserRoutes.DetailsWithBid, {
    id: String(bid.owner.id),
    bidId: String(bid.parent_id || bid.id),
    statusBid: bid.status,
  })

  return (
    <KeyValue label={t(`deal:bid_type:${bid.bid_type}`)}>
      <EntityWithIconsWrapper>
        <TruncatedValue hint={bid.company.name}>
          <ARouterLink
            eventName="company"
            itemId={bid.company.id}
            to={generatePath(CompanyRoutes.Details, { id: bid.company.id })}
          >
            {bid.company.name}
          </ARouterLink>
        </TruncatedValue>
        <CompanyFactoringIcon
          company={bid.company}
          profileType={bid.bid_type === BidTypes.sale ? CompanyMembersTypes.seller : CompanyMembersTypes.customer}
        />
      </EntityWithIconsWrapper>
      <div>{companyPersonalNumber}</div>

      <EntityWithIconsWrapper>
        <TruncatedValue hint={getUserName(bid.owner)}>
          <ARouterLink eventName="user" itemId={bid.owner.id} to={userBidLink}>
            {getUserName(bid.owner)}
          </ARouterLink>
        </TruncatedValue>
        <KeyUserIcon isVisible={bid.owner.profile?.is_significant} />
        <VerifiedUserIcon isVisible={bid.owner.profile?.is_verified} />
        <UserHasDealsIcon isVisible={bid.owner.profile?.has_shipped_deals} />
      </EntityWithIconsWrapper>

      <APhone phone={getUserPhone(bid.owner)} />
    </KeyValue>
  )
}
