import { Text } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { FC } from 'react'
import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

const CardContainer = styled(Card.Container).attrs({
  intent: 'backgroundPrimary',
  disableShadow: true,
})`
  padding: 4px;
`

const TooltipContainer = styled.div`
  max-width: 350px;
  white-space: pre-line;
`

type Props = {
  lastComment: string
}

export const CallsComments: FC<Props> = ({ lastComment }) => {
  return (
    <>
      {lastComment ? (
        <CardContainer>
          <TruncatedValue hint={<TooltipContainer>{lastComment}</TooltipContainer>}>
            <Text as="span" typography="bodySmall">
              {lastComment}
            </Text>
          </TruncatedValue>
        </CardContainer>
      ) : (
        DASH
      )}
    </>
  )
}
