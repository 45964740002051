import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormikFieldsType } from 'modules/domain/types'
import { FormItemWrapper } from 'views/styled/common'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

export const USSaleFields: FC<{ formik: FormikFieldsType }> = ({ formik }) => {
  const { t } = useTranslation('bid')
  const handlerRadio = (v) => {
    formik.setFieldValue('is_firm', v)
    formik.setFieldValue('hedge_ticket', null)
  }
  return (
    <>
      <FormGroup label={t('form.firmOffer')}>
        <FormItemWrapper>
          <Radio value={formik.values.is_firm} onChange={handlerRadio}>
            <RadioItem value={true} label={t('common:yes')} />
            <RadioItem value={false} label={t('common:no')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>

      <FormGroup label={t('form.hedgeTicket')} error={formik.errors.hedge_ticket}>
        <Input
          {...formik.getFieldProps('hedge_ticket')}
          invalid={!!formik.errors.hedge_ticket}
          disabled={!formik.values.is_firm}
          type="number"
        />
      </FormGroup>
    </>
  )
}
