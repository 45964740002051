import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { UserDeal } from 'modules/domain/userBid/types'
import { FC, useEffect, useState } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { DealReason, DealStatus } from './MatchCard'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { MatchesPanel } from './MatchesPanel'
import { ListResponse } from 'types/api'

type Props = {
  activeBid: Bid | undefined
  statuses: DealStatus[]
  reasons: DealReason[]
}

export const MatchesColumn: FC<Props> = ({ activeBid, reasons, statuses }) => {
  const { t } = useTranslation()
  const [matches, setMatches] = useState<UserDeal[]>([])
  const [closedMatches, setClosedMatches] = useState<UserDeal[]>([])
  const [completedMatches, setCompletedMatches] = useState<UserDeal[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)

  const getDeals = () => {
    if (activeBid?.id) {
      setProgress(Progress.WORK)
      setMatches([])
      setClosedMatches([])
      setCompletedMatches([])
      const respMatches = apiClient.get<ListResponse<UserDeal>>(endpoints.matchesForBids(activeBid.id))
      const respClosedMatches = apiClient.get<UserDeal[]>(endpoints.closedMatchesForBids(activeBid.id))
      const respCompletedMatches = apiClient.get<UserDeal[]>(endpoints.completedMatchesForBids(activeBid.id))

      Promise.all([respMatches, respClosedMatches, respCompletedMatches])
        .then((values) => {
          setProgress(Progress.SUCCESS)
          setMatches(values[0].results)
          setClosedMatches(values[1])
          setCompletedMatches(values[2])
        })
        .catch((e) => {
          setProgress(Progress.ERROR)
          console.error(e)
        })
    }
  }

  useEffect(() => {
    getDeals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  return (
    <WrapperCollapse>
      <AntCollapse noMarginTop accordion defaultActiveKey={['matches']}>
        <MatchesPanel
          namePanel={t('user:list.matches')}
          onStatusChange={getDeals}
          activeBid={activeBid}
          statuses={statuses}
          colorCount="orange"
          progress={progress}
          matches={matches}
          reasons={reasons}
          key="matches"
        />
        <MatchesPanel
          namePanel={t('user:list.completed_deals')}
          matches={completedMatches}
          onStatusChange={getDeals}
          activeBid={activeBid}
          statuses={statuses}
          progress={progress}
          reasons={reasons}
          colorCount="grey"
          key="completed"
        />
        <MatchesPanel
          namePanel={t('user:list.closed_deals')}
          onStatusChange={getDeals}
          matches={closedMatches}
          activeBid={activeBid}
          statuses={statuses}
          progress={progress}
          reasons={reasons}
          colorCount="grey"
          key="closed"
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
