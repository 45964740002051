import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import { useFilters } from 'hooks/useFilter'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { Warehouse, WarehouseStatuses } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/NewLayout'
import WarehousesRoutes from 'views/pages/Warehouses/routes'
import * as Header from 'views/ui/Header/Header'
import APhone from 'views/ui/Phone/APhone'
import { ArchiveWarehouse } from './ArchiveWarehouse'
import { WarehouseDetailFilters } from './WarehouseDetailFilters'
import { WarehouseDetailTable } from './WarehouseDetailTable/WarehouseDetailTable'

const defaultFilter = { not_closed: 'true', movement_type: 'income', sort: '-created' }

export const WarehouseDetail: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation('warehouses')
  const [progress, warehouse, refetch] = useSingleEntity<Warehouse>(endpoints.warehouses(id))
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    filter: defaultFilter,
  })

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const archiveOnSuccess = () => {
    refetch(true)
  }

  useHelmet({ title: `${t('warehouse')} - ${warehouse?.title}` })

  if (!warehouse) return null
  if (progress === Progress.WORK) return <ItemLoadingLayout id={id} />

  return (
    <APageWrapper page="WarehousesDetail" place="WarehousesDetail">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  path: WarehousesRoutes.List,
                  breadcrumbName: t('title'),
                },
                {
                  breadcrumbName: warehouse.title,
                },
              ]}
            />
            <ArchiveWarehouse refetch={archiveOnSuccess} status={warehouse.status as WarehouseStatuses} />
          </Layout.TopHeader>
          <Layout.PageName style={{ marginBottom: '8px' }}>{warehouse.title}</Layout.PageName>
          <Layout.DetailPageSubTitle>
            <div>
              {warehouse.owner.profile?.company && <CompanyLink company={warehouse.owner.profile?.company} />}{' '}
              {warehouse.owner.profile?.company?.inn &&
                `/ ${getPersonalNumberLabel()}: ${warehouse.owner.profile?.company?.inn} / `}{' '}
              {warehouse.owner.phone && <APhone phone={warehouse.owner.phone} />}
            </div>
            <div>{warehouse.address.address}</div>
          </Layout.DetailPageSubTitle>
          <WarehouseDetailFilters
            defaultFilterValues={defaultFilter}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            setListRequestParams={setListRequestParams}
          />
        </Layout.Header>

        <WarehouseDetailTable
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          isFiltersLoaded={isFiltersLoaded}
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
