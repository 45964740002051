import { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { useHelmet } from '@agro-club/agroclub-shared'

import { useAnalyticsContext } from 'analytics/hooks'
import { isUS } from 'env'
import { AuthContext } from 'modules/context/AuthContext'
import { TableDataObject, refetchFunc, useTableData } from 'modules/domain/common/hooks'
import { AAntdTabs } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import * as Layout from 'views/layouts/NewLayout'
import { DashboardFilters } from 'views/pages/Dashboard/DashboardFilters'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import * as Header from 'views/ui/Header/Header'
import { CardList } from './components/Cards/CardList'
import { DashboardTasksList } from './components/DashboardTasksList'
import { useCallsColumns } from './hooks/useCallsColumns'
import { useDashboardTabsConfig } from './hooks/useDashboardTabsConfig'
import { useNewUserColumns } from './hooks/useNewUserColumns'
import { DashboardDataType, DashboardTab } from './types'

const getTabFetchFunction = (tabsData: Record<string, TableDataObject<DashboardDataType>>, key: DashboardTab) =>
  (async (silent) => {
    if (key == DashboardTab.newUsers) {
      await Promise.all([tabsData[DashboardTab.calls].refetch(silent), tabsData[DashboardTab.newUsers].refetch(silent)])
      return
    }
    return tabsData[key].refetch(silent)
  }) as refetchFunc

const defaultTab = isUS ? DashboardTab.matches : DashboardTab.calls

export const Dashboard: FC = () => {
  const { setOptions } = useAnalyticsContext()
  const { profile: currentUser } = useContext(AuthContext)
  const { activeTab: activeTabParams } = useParams()

  const [activeTab, setActiveTab] = useState<string>(activeTabParams || defaultTab)

  const { t } = useTranslation('dashboard')
  useHelmet({ title: t('metaTitle') })

  const tabsConfig = useDashboardTabsConfig(activeTab, currentUser)

  const tabsData: Record<string, TableDataObject<DashboardDataType>> = {}
  Object.entries(tabsConfig.tabs).forEach((tab) => {
    const [key, val] = tab
    // eslint-disable-next-line react-hooks/rules-of-hooks
    tabsData[key] = useTableData<DashboardDataType>(
      tabsConfig.tabs[key].endpoint || '',
      val.params,
      tabsConfig.isFiltersLoaded,
    )
  })

  const navigate = useNavigate()

  useEffect(() => {
    navigate(
      {
        pathname: generatePath(DashboardRoutes.ListWithTabs, { activeTab: activeTab }),
        search: window.location.search,
      },
      { replace: true },
    )
    setOptions({
      place: activeTab,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])
  const usePrepareTabForRender = (key, render: (DashboardTabItemProps) => ReactElement) => {
    const { params, label, setter } = tabsConfig.tabs[key]
    const { data, progress, total, refetch, pageSize } = tabsData[key]
    const props = {
      data,
      progress,
      total,
      refetch,
      pageSize,
      fetchFunc: getTabFetchFunction(tabsData, key),
      key,
      listRequestParams: params,
      listParamsUpdated: setter,
    }
    return {
      label,
      key,
      // without memo cards list with many elements works slow
      // eslint-disable-next-line react-hooks/exhaustive-deps
      children: useMemo(() => render(props), [data, total, params, progress]),
      count: total,
    }
  }

  const tabsItems = [
    usePrepareTabForRender(DashboardTab.responses, (props) => <CardList variant="deal" {...props} />),
    usePrepareTabForRender(DashboardTab.matches, (props) => <CardList variant="deal" {...props} />),
    usePrepareTabForRender(DashboardTab.calls, (props) => (
      <DashboardTasksList successText={t('task:notifyTaskCompleted')} useColumns={useCallsColumns} {...props} />
    )),
    usePrepareTabForRender(DashboardTab.newBids, (props) => <CardList variant="bid" {...props} />),
    usePrepareTabForRender(DashboardTab.newUsers, (props) => (
      <DashboardTasksList successText={t('newUsers:userHandled')} useColumns={useNewUserColumns} {...props} />
    )),
    usePrepareTabForRender(DashboardTab.overdueBids, (props) => <CardList variant="bid" {...props} />),
  ]

  const resultTabItems = isUS
    ? tabsItems.filter(
        (tab) =>
          tab.key !== DashboardTab.responses && tab.key !== DashboardTab.calls && tab.key !== DashboardTab.overdueBids,
      )
    : tabsItems

  return (
    <APageWrapper page="dashboard" place="Dashboard">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('menu:dashboard'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('menu:dashboard')}</Layout.PageName>
          <DashboardFilters
            activeTab={activeTab as DashboardTab}
            listRequestParams={tabsConfig.tabs[activeTab].params}
            updateCommonFilterState={tabsConfig.updateCommonFilterState}
            updateFilterState={tabsConfig.tabs[activeTab].updateFilterState}
            tabSetter={tabsConfig.tabs[activeTab].setter}
            resetFilters={tabsConfig.clearAllTabsState}
          />
        </Layout.Header>
        <AAntdTabs
          id="tabs"
          items={resultTabItems}
          onChange={(tab) => setActiveTab(tab)}
          activeKey={activeTab}
          fullHeight
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
