import { FC, useState } from 'react'

import { FormikProps } from 'formik'
import { CreateDealFields } from './types'
import { ProfileType, User } from 'modules/domain/user/types'
import { BidTypes, USBid } from 'modules/domain/bid/types'
import FormSelectShared from 'views/components/FormSelectShared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { getUserName } from 'modules/domain/user/utils'
import { OptionType } from '@agro-club/agroclub-shared'

interface Props {
  formik: FormikProps<CreateDealFields>
  bid: USBid
  setUser: (user: User | undefined) => void
}

export const UserCompanyControl: FC<Props> = ({ formik, setUser, bid }) => {
  const { t } = useTranslation('deal')
  const [companyFilter] = useState<Record<string, any>>({
    deal_side: bid.bid_type == BidTypes.sale ? BidTypes.purchase : BidTypes.sale,
  })
  const [useFilter] = useState<Record<string, any>>({
    profile_type: [ProfileType.manager, bid.bid_type == BidTypes.sale ? ProfileType.customer : ProfileType.seller],
  })
  const onOptionsChanged = (options: OptionType[]) => {
    if (options.length == 1) {
      formik.setFieldValue('user', options[0].value)
    }
  }
  return (
    <>
      <FormSelectShared
        formik={formik}
        fieldName="company"
        filter={companyFilter}
        label={t('common:company')}
        endpoint={endpoints.company()}
        onChange={(val) => {
          formik.setFieldValue('company', val)
          formik.setFieldValue('user', null)
          setUser(undefined)
        }}
        searchField="search"
        required
      />
      <FormSelectShared
        label={t('common:user')}
        formik={formik}
        filter={useFilter}
        endpoint={formik.values.company ? endpoints.activeEmployees(formik.values.company.toString()) : undefined}
        simpleApiFormat
        getLabel={(dto) => getUserName(dto)}
        onSelectedOptionLoaded={(option) => option && setUser(option.dto)}
        fieldName="user"
        disabled={!formik.values.company}
        required
        onOptionsChanged={onOptionsChanged}
        searchField="search"
      />
    </>
  )
}
