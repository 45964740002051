import { FC } from 'react'
import { Route, Routes } from 'react-router'
import { AccountManagement } from './AccountManagementList'
import AccountManagementRoutes from './routes'

export const AccountManagementPage: FC = () => {
  return (
    <Routes>
      <Route index element={<AccountManagement />} />
      <Route path={AccountManagementRoutes.relativeList} element={<AccountManagement />} />
    </Routes>
  )
}
