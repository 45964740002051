import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { removePropertyNamePrefix, replaceErrorsDotNotation } from 'modules/utils/helpers'
import { FormPatronymicName } from 'views/components/form/FormPatronymicName'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { FormFirstName } from 'views/components/form/FormFirstName'
import { FormLastName } from 'views/components/form/FormLastName'
import { FormCompany } from 'views/components/form/FormCompany'
import { FormTeam } from 'views/components/form/FormTeam'
import { ShortFormControl } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { Fields } from './AddUser'
import { useFormik } from 'formik'
import env, { isRussia } from 'env'
import { useAFormHandler } from 'analytics/hooks'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { FormPhoneInput } from 'views/components/form/FormPhoneInput'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'

interface Props {
  onClose: (boolean) => void
  userRefetch: () => void
  user: User
}

export const EditUser: FC<Props> = ({ onClose, user, userRefetch }) => {
  const { t } = useTranslation(['user'])
  const { formProgress, formHandler } = useAFormHandler()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const formik = useFormik<Fields>({
    initialValues: {
      recommended_margin_percent: user.profile?.recommended_margin_percent || null,
      team: user.profile?.team?.id ? String(user.profile?.team?.id) : null,
      patronymic_name: user.profile?.patronymic_name || '',
      inn: user.profile?.inn || '',
      credit_limit: user.profile?.credit_limit || null,
      profile_type: user.profile?.profile_type || null,
      first_name: user.profile?.first_name || '',
      company: user.profile?.company?.id || null,
      last_name: user.profile?.last_name || '',
      email: user.email || '',
      is_mobile_phone: user.is_mobile_phone,
      phone: user.phone,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          email: formik.values.email,
          is_mobile_phone: formik.values.is_mobile_phone,
          profile: {
            recommended_margin_percent: formik.values.recommended_margin_percent
              ? formik.values.recommended_margin_percent
              : null,
            credit_limit: formik.values.credit_limit ? formik.values.credit_limit : null,
            patronymic_name: formik.values.patronymic_name,
            profile_type: formik.values.profile_type,
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            company: formik.values.company,
            inn: formik.values.inn,
            team: formik.values.team,
          },
        }

        return await apiClient.put(endpoints.users(user?.id), data)
      },
      {
        onSuccess: async () => {
          await userRefetch()
          onClose(false)
        },
        onError: (error) => {
          const { errors } = error
          const formattedErrors = replaceErrorsDotNotation(errors)
          formik.setErrors(removePropertyNamePrefix(formattedErrors, /profile_/))
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EDIT_MODAL} size="medium" onClose={() => onClose(false)}>
      <SharedModalTitle>{t('form.edit')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormLastName formik={formik} />
          <FormFirstName formik={formik} />
          <FormPhoneInput formik={formik} isEdit />
          <FormPatronymicName formik={formik} />
          {isRussia && (
            <PermissionCheck scope={['users.can_edit_inn']}>
              <FormGroup label={t('form.fields.user_name')} error={formik.errors.inn}>
                <Input {...formik.getFieldProps('inn')} invalid={!!formik.errors.inn} />
              </FormGroup>
            </PermissionCheck>
          )}

          <FormGroup label={t('form.fields.email')} error={formik.errors.email}>
            <Input
              placeholder={t('form.email_placeholder')}
              {...formik.getFieldProps('email')}
              invalid={!!formik.errors.email}
            />
          </FormGroup>
          {!user?.profile?.company?.inn && <FormCompany formik={formik} />}
          <ShortFormControl>
            <FormTeam formik={formik} />
            <FormGroup
              label={t('form.fields.recommendedMarginPercent')}
              error={formik.errors.recommended_margin_percent}
            >
              <Input
                {...formik.getFieldProps('recommended_margin_percent')}
                invalid={!!formik.errors.recommended_margin_percent}
                type="number"
                min={0}
              />
            </FormGroup>
            <FormGroup label={t('form.fields.justCreditLimit', { currency })} error={formik.errors.credit_limit}>
              <Input
                {...formik.getFieldProps('credit_limit')}
                invalid={!!formik.errors.credit_limit}
                type="number"
                min={0}
              />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        onClose={() => onClose(false)}
      />
    </ASharedModal>
  )
}
