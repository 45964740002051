import { ColumnsType } from '@agro-club/agroclub-shared'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import APhone from 'views/ui/Phone/APhone'
import { generatePath } from 'react-router-dom'
import DealRoutes from 'views/pages/Deal/routes'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { Driver, LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import styled from 'styled-components'
import { DASH } from 'modules/constants'
import { DriverCell } from '../../components/DriverCell'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import { LoadUploadCell } from 'views/pages/LogisticsKR/LogisticRequests/components/LoadUploadCell/LoadUploadCell'

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type SellerCellProps = {
  data: LogisticsKRTrip
}

const SellerCell: FC<SellerCellProps> = ({ data }) => {
  const { t } = useTranslation('common')

  return (
    <FlexColumnWrapper>
      <TableTextLink
        id="companyDetail"
        to={generatePath(CompanyRoutes.Details, { id: data.owner?.profile?.company?.id })}
      >
        {data.owner?.profile?.company?.name}
      </TableTextLink>
      {t('inn')}: {data.owner?.profile?.company?.inn}
      <TableTextLink id="userDetail" to={generatePath(UserRoutes.Details, { id: data.owner?.id })}>
        {data.owner?.profile?.full_name}
      </TableTextLink>
      {data?.owner?.phone ? <APhone phone={data?.owner?.phone} /> : DASH}
    </FlexColumnWrapper>
  )
}

const onCell = () => ({
  style: {
    verticalAlign: 'top',
  },
})

export const useColumns = (tripsRefetch: () => void): ColumnsType<LogisticsKRTrip> => {
  const { t } = useTranslation('logisticsKR')

  return useMemo(
    () => [
      {
        title: t('withoutTtnTable.headers.deal_id'),
        width: 120,
        dataIndex: 'deal_id',
        render: (deal_id) => (
          <TableTextLink id="dealDetail" to={generatePath(DealRoutes.Details, { id: deal_id })}>
            {deal_id}
          </TableTextLink>
        ),
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.transport_request_id'),
        width: 172,
        dataIndex: 'request_id',
        render: (request_id) => (
          <TableTextLink id="requestDetail" to={generatePath(DealsKRRoutes.Details, { id: request_id })}>
            {request_id}
          </TableTextLink>
        ),
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.ttn_number'),
        width: 98,
        dataIndex: 'ttn_number',
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.load'),
        width: 200,
        dataIndex: 'load',
        render: (_, data) => <LoadUploadCell loadType="load" trip={data} onSuccess={tripsRefetch} />,
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.unload'),
        width: 200,
        dataIndex: 'unload',
        render: (_, data) => <LoadUploadCell loadType="unload" trip={data} onSuccess={tripsRefetch} />,
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.logist'),
        width: 150,
        dataIndex: 'logistician',
        render: (logistician) => (logistician ? logistician.short_name || logistician.full_name : DASH),
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.carrier'),
        width: 415,
        dataIndex: 'carrier',
        render: (carrier, data) => <DriverCell driver={data.driver as Driver} logistics_request={data} />,
        onCell,
      },
      {
        title: t('withoutTtnTable.headers.seller'),
        width: 415,
        dataIndex: 'seller',
        render: (_, data) => <SellerCell data={data} />,
        onCell,
      },
    ],
    [t],
  )
}
