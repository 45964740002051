const main = 'account-management'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const AccountManagementRoutes = { ...relativeRoutes, ...routes }

export default AccountManagementRoutes
