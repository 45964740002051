import _ from 'lodash'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { QuantityLoadPerDayForm } from './QuantityLoadPerDayForm'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { refetchFunc } from 'modules/domain/common/hooks'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { Card } from 'views/components/Card/Card'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { isStatusAfter } from '../helpers'
import { FC, useState } from 'react'
type Props = {
  deal: Deal
  bidType: BidTypes
  refetch: refetchFunc
}

export const QuantityLoadPerDay: FC<Props> = ({ deal, bidType, refetch }) => {
  const { t } = useTranslation('deal')
  const [isEdit, setIsEdit] = useState(false)

  const isMobile = useMediaQuery({ query: `(max-width: ${defaultTheme.queries.mobile})` })

  const isSaleBid = bidType === BidTypes.sale

  let countField, permissionScope, labelKey

  if (isSaleBid) {
    countField = 'loading_count'
    permissionScope = ['deals.can_change_loading_count']
    labelKey = 'loading_count'
  } else {
    countField = 'unloading_count'
    permissionScope = ['deals.can_change_unloading_count']
    labelKey = 'unloading_count'
  }

  const isStatusAfterAgreed = isStatusAfter(deal.status, DealStatusTypes.AGREED)

  const handleClose = () => {
    setIsEdit(false)
  }

  if (!isStatusAfterAgreed) return null

  return (
    <BorderedItem hover={false}>
      <KeyValue label={t(labelKey)}>
        {isEdit && isMobile ? (
          <QuantityLoadPerDayForm deal={deal} countField={countField} refetch={refetch} onClose={handleClose} />
        ) : !_.isNil(deal.trip_count_norm?.[countField]) ? (
          t('truck', { count: deal.trip_count_norm?.[countField] })
        ) : (
          t('no_trucks_count')
        )}
      </KeyValue>

      <PermissionCheck scope={permissionScope}>
        {!isEdit ? <Card.EditButton id="editValuePerDay" onClick={() => setIsEdit(true)} /> : null}
      </PermissionCheck>

      {!isMobile && isEdit && (
        <QuantityLoadPerDayForm deal={deal} countField={countField} refetch={refetch} onClose={handleClose} />
      )}
    </BorderedItem>
  )
}
