import { FC } from 'react'
import { FormikProps } from 'formik'
import { BidAddFields, BidTypes } from 'modules/domain/bid/types'
import { PotentialBidAddFields } from 'modules/domain/potentialBid/types'
import { useTranslation } from 'react-i18next'
import { ProfileType, User } from 'modules/domain/user/types'
import { FormCompany } from 'views/components/form/FormCompany'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { getUserName } from 'modules/domain/user/utils'
import { FormAddress } from 'views/components/form/FormAddress/FormAddress'
import { isRussia, isUS } from 'env'
import { ShortFormControl } from 'views/styled/common'
import { FormEnumSelect } from 'views/components/form/EnumSelect/FormEnumSelect'

type Props = {
  formik: FormikProps<BidAddFields> | FormikProps<PotentialBidAddFields>
  setOwnerProfileType?: (profile?: ProfileType) => void
  ownerProfileType?: ProfileType
}

export const CommonBidModalControls: FC<Props> = ({ formik, setOwnerProfileType, ownerProfileType }) => {
  const { t } = useTranslation('bid')
  const userId = formik.values.owner
  const companyId = formik.values.company

  const getEmployeeLabel = (u: User) => {
    const profileType = u?.profile?.profile_type
    const userName = getUserName(u)
    if (!profileType) {
      return userName
    }

    const profileTypeTranslated = t(`user:profile_type.${profileType}`)
    return `${userName} (${profileTypeTranslated})`
  }

  const onChangeCompany = (company: string) => {
    formik.setFieldValue('company', company)
    setOwnerProfileType?.()
    onChangeUser()
  }

  const onChangeUser = (owner?: string) => {
    formik.setFieldValue('owner', owner)
    formik.setFieldValue('address', null)
    formik.setFieldValue('bid_type', null)
    setOwnerProfileType?.()
    if (isUS) {
      formik.setFieldValue('is_firm', false)
      formik.setFieldValue('hedge_ticket', undefined)
    }
  }

  const showBidTypeControl = ownerProfileType === ProfileType.manager && !isRussia

  return (
    <>
      <FormCompany required formik={formik} onChange={onChangeCompany} showInn />
      <FormSelectShared
        label={t('form.user')}
        placeholder={t('form.selectEmployeePlaceholder')}
        formik={formik}
        endpoint={companyId && endpoints.activeEmployees(companyId)}
        simpleApiFormat
        getLabel={(dto) => getEmployeeLabel(dto)}
        onChange={onChangeUser}
        onSelectedOptionLoaded={(e) => setOwnerProfileType?.(e?.dto?.profile?.profile_type)}
        fieldName="owner"
        showSearch={false}
        disabled={!companyId}
        required
      />
      {showBidTypeControl && (
        <ShortFormControl>
          <FormEnumSelect
            translationNameSpace={'bid:bidTypes'}
            fieldName="bid_type"
            enumType={BidTypes}
            label={t('bid')}
            formik={formik}
          />
        </ShortFormControl>
      )}
      {userId && companyId && (
        <FormAddress
          label={t('bid:form.address')}
          companyId={companyId}
          disabled={!userId}
          formik={formik}
          userId={userId}
          required
        />
      )}
    </>
  )
}
