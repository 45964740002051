import { FactoringStatus } from 'modules/domain/company/types'
import { Comment } from 'views/components/Comments/hooks'

export enum DispatchingTypes {
  open = 'open',
  closed = 'closed',
}

export enum DispatchingStatuses {
  reconciling = 'reconciling',
  rate_reconciled = 'rate_reconciled',
  contract_signed = 'contract_signed',
  documents = 'documents',
  search = 'search',
  cpt = 'cpt',
  closed = 'closed',
  urgent = 'urgent',
  pause = 'pause',
  car_restrictions = 'car_restrictions',
  no_car_needed = 'no_car_needed',
}

export enum PaymentStatus {
  UNDEFINED_STATUS = 'undefined_status',
  PAYMENT_REQUIRED = 'payment_required',
  PAYMENT_DOCUMENT_SET = 'payment_document_set',
  BANK_FACTORING_PAYMENT_SET = 'bank_factoring_payment_set',
  PAID = 'paid',
}

export interface PaymentInfoItemType {
  value: PaymentStatus
  color: string
  borderColor?: string
  checkMarkColor?: string
}

export interface Payment {
  amount: number
  status: PaymentStatus
  bank?: Bank
  loan_agreement?: LoanAgreement
  source?: PaymentSources
}

// for mobile
export type PaymentWithDate = Payment & {
  date: string
}

export type EmptyPaymentWithDate = Partial<Payment> & {
  date: string
}

export interface DispatchingItem {
  id: string
  status: DispatchingStatuses
  price_exw: number
  trips_expected: number | null
  payments: {
    [x: string]: Payment
  }
}

export interface DispatchingItemFormik {
  status: DispatchingStatuses | undefined
  price_exw: number | undefined
  trips_expected: number | null | undefined
  payments: {
    [x: string]: {
      amount: number
      status: PaymentStatus
      bank?: string
      loan_agreement?: string
      source?: PaymentSources
    }
  }
}
export type DispatchingListType = {
  id: string
  deal_id: string
  product_title: string
  status: DispatchingStatuses
  seller: {
    factoring_seller_status?: FactoringStatus
    factoring_customer_status?: FactoringStatus
    id: string
    inn: string
    name: string
    state_reg_number: string
  }
  seller_address_title: string
  customer: {
    factoring_seller_status?: FactoringStatus
    factoring_customer_status?: FactoringStatus
    id: string
    inn: string
    name: string
    state_reg_number: string
  }
  customer_address_title: string
  quantity: number
  planned_remaining_quantity: number
  price_exw: number
  cost: number
  remaining_cost: number
  logistician: {
    id: string
    is_active: boolean
    full_name: string
    phone: string
  }
  logisticians: {
    id: string
    is_active: boolean
    full_name: string
    phone: string
  }[]
  comments: Comment[]
  quantity_paid_for: number
  loaded_quantity: number
  delivered_quantity: number
  remaining_quantity: number
  trips_norm_percent: number
  trips_today: number
  trips_expected: number
  trips_tomorrow: number
  logistics_request_id: number
  specification_deadline: string
  purchase_specification_id: number
  without_documents_request: boolean
  without_purchase_specification: boolean
  documents_request_expected_complete_date: string
  payments: {
    [x: string]: Payment
  }
}

export type Bank = {
  id: number
  name: string
  short_name: string
}

export type LoanAgreement = {
  id: number
  name: string
  bank: string
  is_active: string
}

export enum PaymentSources {
  OWN = 'own',
  CREDIT = 'credit',
  FACTORING = 'factoring',
}
