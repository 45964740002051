import { FC, useEffect, useState } from 'react'

import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { ProfileType, User } from 'modules/domain/user/types'
import { BidAddFields, BidKind, BidPrice, BidStatus, BidTags, BidTypes } from 'modules/domain/bid/types'
import { FormGroup, Input, defaultTheme } from '@agro-club/agroclub-shared'
import { WarningContainer } from 'views/pages/Bid/BidModals/styled'
import { CommonBidModalControls } from '../CommonBidModalControls'
import { ShortFormControl } from 'views/styled/common'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { BrazilSubForm } from '../../components/Brazil/BrazilSubForm'
import { PricePeriodsForm } from '../../components/Futures'
import { BidParamsInputs } from './BidParamsInputs'
import { TypePriceControl } from '../../components/Brazil/TypePriceControl'
import { isBidProductParamsEnabled } from 'env'
import { Product } from 'modules/domain/collection/types'
import { generatePath, useNavigate } from 'react-router'
import { apiClient } from 'modules/utils/httpClient'
import BidRoutes from '../../routes'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import FormSelectShared from 'views/components/FormSelectShared'
import { SeasonSelect } from '../../components/SeasonSelect'
import { useAFormHandler } from 'analytics/hooks'
import { useMediaQuery } from 'react-responsive'
import { MobileModalFooter } from 'views/components/MobileComponents/Modal/MobileModalFooter'

export const InitialMainParameter = {
  parameter: undefined,
  parameter_value_from: undefined,
  parameter_value_to: undefined,
}

export type BidFormProps = {
  user?: User
  onClose: () => void
  bidKind: BidKind
  onSuccess?: (value) => void
  children?: React.ReactNode
}

export const BidAddForm: FC<BidFormProps> = ({ user, bidKind, onClose, onSuccess, children }) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler('create')
  const [product, setProduct] = useState<Product | undefined>()
  const navigate = useNavigate()
  const isFuturesBid = bidKind === BidKind.futures

  const [ownerProfileType, setOwnerProfileType] = useState<ProfileType | undefined>(user?.profile?.profile_type)

  const navigateToNewBid = (id: string) => {
    onClose()
    navigate(generatePath(BidRoutes.Details, { id }))
  }

  const formik = useFormik<BidAddFields>({
    initialValues: {
      bid_type: ownerProfileType === ProfileType.customer ? BidTypes.purchase : BidTypes.sale,
      company: user?.profile?.company?.id || undefined,
      owner: user?.id ? String(user.id) : undefined,
      address: undefined,
      product: undefined,
      price: undefined,
      price_type: undefined,
      quantity: undefined,
      parameters: [],
      mainParameter: InitialMainParameter,
      owner_delivery_address: undefined,
      price_periods: [{ start_date: null, end_date: null, price: null, status: BidStatus.published }],
      aground_imputs: undefined,
      season: undefined,
    },
    onSubmit: formHandler(
      async () => {
        const dto = {
          bid_type: formik.values.bid_type,
          company: formik.values.company,
          quantity: formik.values.quantity,
          product: formik.values.product,
          owner: formik.values.owner,
          address: formik.values.address,
          price: formik.values.price,
          price_type: formik.values.price_type,
          aground_imputs: formik.values.aground_imputs,
          bid_tag: formik.values.bid_tag,
          owner_delivery_address: formik.values.owner_delivery_address,
          parameters: [formik.values.mainParameter, ...formik.values.parameters],
          season: formik.values.season,
        }
        if (isFuturesBid) {
          dto['price'] = 0
          dto['price_periods'] = formik.values.price_periods
        }

        if (!isBidProductParamsEnabled) {
          dto['parameters'] = []
        }

        return await apiClient.post<{ id: string }>(endpoints.bid(), dto)
      },
      {
        onSuccess: async (requestResult) => {
          if (onSuccess) {
            await onSuccess(requestResult)
          } else {
            await navigateToNewBid(requestResult.id)
          }
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    if (!product) {
      return
    }
    formik.setValues({
      ...formik.values,
      parameters: [],
      mainParameter: {
        parameter: product.main_parameter?.id,
        parameter_value_from: undefined,
        parameter_value_to: undefined,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    if (!ownerProfileType) return

    const priceType = ownerProfileType === ProfileType.customer ? BidPrice.CPT : BidPrice.EXW
    formik.setFieldValue('price_type', priceType)

    if (ownerProfileType === ProfileType.customer) {
      formik.setFieldValue('bid_type', BidTypes.purchase)
      formik.setFieldValue('bid_tag', BidTags.EXPORT)
    } else {
      formik.setFieldValue('bid_type', BidTypes.sale)
      formik.setFieldValue('bid_tag', undefined)
      formik.setFieldValue('aground_imputs', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerProfileType])

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      <SharedModalContent>
        <WarningContainer>
          <div>{t('form.createWarning')}</div>
        </WarningContainer>
        <SharedModalInputs>
          {children}

          <CommonBidModalControls
            setOwnerProfileType={setOwnerProfileType}
            ownerProfileType={ownerProfileType}
            formik={formik}
          />
          {formik.values.owner && ownerProfileType && (
            <TypePriceControl formik={formik} userId={formik.values.owner} companyId={formik.values.company} />
          )}
          <FormSelectShared
            label={t('form.product')}
            placeholder={t('form.selectProductPlaceholder')}
            formik={formik}
            onClear={() => setProduct(undefined)}
            onSelectedOptionLoaded={(e) => setProduct(e.dto)}
            endpoint={endpoints.products()}
            simpleApiFormat
            getLabel={(dto) => dto.title}
            onChange={(value) => {
              formik.setFieldValue('season', undefined)
              formik.setFieldValue('product', value)
            }}
            fieldName="product"
            showSearch={false}
            required
          />
          <SeasonSelect formik={formik} disabled={!formik.values.product} />
          {!isFuturesBid && (
            <ShortFormControl>
              <FormGroup label={labelTarif(t('common:price.default'))} error={formik.errors.price} required>
                <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type={'number'} />
              </FormGroup>
            </ShortFormControl>
          )}
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
          </ShortFormControl>
        </SharedModalInputs>
        <BrazilSubForm formik={formik} profileType={ownerProfileType} product={product} />
        {isFuturesBid && <PricePeriodsForm formik={formik} priceLabel={labelTarif(t('common:price.default'))} />}
        <BidParamsInputs formik={formik} product={product} />
      </SharedModalContent>
      {isMobile ? (
        <MobileModalFooter
          onConfirm={() => formik.handleSubmit()}
          confirmButtonText={t('common:add')}
          disabled={!formik.dirty}
          progress={formProgress}
          onClose={onClose}
        />
      ) : (
        <ADefaultModalFooter
          onConfirm={() => formik.handleSubmit()}
          confirmButtonText={t('common:add')}
          disabled={!formik.dirty}
          progress={formProgress}
          onClose={onClose}
        />
      )}
    </>
  )
}
